









































import {Component, Vue} from 'vue-property-decorator';

@Component({})
export default class UserCenterHeader extends Vue {

  get nickName() {
    return localStorage.getItem('Keep_NickName')
  }

  get faceUrl() {
    return localStorage.getItem('Keep_FaceUrl')
  }

  get isCoach() {
    const shipType = localStorage.getItem('Keep_ShipType')
    return shipType === 'coach'
  }

};
